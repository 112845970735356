@font-face {
  font-family: 'Roboto-Black';
  src: url(/static/media/Roboto-Black.301fe70f.ttf);
}

@font-face {
  font-family: 'Roboto-BlackItalic';
  src: url(/static/media/Roboto-BlackItalic.c470ca2b.ttf);
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url(/static/media/Roboto-Bold.9ece5b48.ttf);
}

@font-face {
  font-family: 'Roboto-BoldItalic';
  src: url(/static/media/Roboto-BoldItalic.0be9fa8f.ttf);
}

@font-face {
  font-family: 'Roboto-Italic';
  src: url(/static/media/Roboto-Italic.465d1aff.ttf);
}

@font-face {
  font-family: 'Roboto-Light';
  src: url(/static/media/Roboto-Light.6090d256.ttf);
}

@font-face {
  font-family: 'Roboto-LightItalic';
  src: url(/static/media/Roboto-LightItalic.2ffc058d.ttf);
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url(/static/media/Roboto-Medium.b2d307df.ttf);
}

@font-face{
  font-family: 'Roboto-MediumItalic';
  src: url(/static/media/Roboto-MediumItalic.cabdb4a1.ttf);
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url(/static/media/Roboto-Regular.f36638c2.ttf);
}

@font-face {
  font-family: 'Roboto-Thin';
  src: url(/static/media/Roboto-Thin.4f0b85f5.ttf);
}

@font-face {
  font-family: 'Roboto-ThinItalic';
  src: url(/static/media/Roboto-ThinItalic.7384da64.ttf);
}


@font-face {
  font-family: 'DMSans-Bold';
  src: url(/static/media/DMSans-Bold.b9cec521.ttf);
}

@font-face {
  font-family: 'DMSans-BoldItalic';
  src: url(/static/media/DMSans-BoldItalic.f83322c8.ttf);
}

@font-face {
  font-family:'DMSans-Italic' ;
  src: url(/static/media/DMSans-Italic.1ea92590.ttf);
}

@font-face {
  font-family: 'DMSans-Medium';
  src: url(/static/media/DMSans-Medium.24bfda97.ttf);
}

@font-face {
  font-family: 'DMSans-MediumItalic';
  src: url(/static/media/DMSans-MediumItalic.a72ea4b3.ttf);
}

@font-face {
  font-family:'DMSans-Regular' ;
  src: url(/static/media/DMSans-Regular.7c217bc9.ttf);
}


:root{
  --Color-Blanco: #fff;
  --Color-Negro: #000;
  --Color-Red-Primary: rgb(228, 43, 49);
  --Color-Grey: #EEEEEE;
  --Color-Grey-Dark:  #7F7F7F;
  --Color-Grey-Light: #F6F6F6;
  --Color-Green-Aproved: #41B96A;
  --Color-Yellow-Paused: #FFC120;

  --Font-Roboto-Regular: 'Roboto-Regular';
  --Font-Roboto-Thin: 'Roboto-Thin';
  --Font-Roboto-Black: 'Roboto-Bold';
  
  --Font-DMSans-Bold: 'DMSans-Bold';
  --Font-DMSans-BoldItalic: 'DMSans-BoldItalic';
  --Font-DMSans-Italic: 'DMSans-Italic';
  --Font-DMSans-Medium:'DMSans-Medium';
  --Font-DMSans-MediumItalic: 'DMSans-MediumItalic';
  --Font-DMSans-Regular: 'DMSans-Regular';

}
body{
    background: radial-gradient(91.5% 210.93% at -13.44% 132.89%, #F9F9F9 0%, rgba(196, 196, 196, 0) 100%);
    max-width: 100vw !important;
}

::-webkit-scrollbar {
    display: none;
}

li{
    list-style: none;
}



.layout-general{
    padding: 0 !important;
    transition: all .25s linear !important;
}

/* RUEDA LOADING ANIMATION */
.Rueda_loading {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  
}
  
.Rueda_loading div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
  
.Rueda_loading div:nth-child(1) {
    left: 8px;
    background: var(--Color-Red-Primary);
    -webkit-animation: lds-ellipsis1 0.6s infinite;
            animation: lds-ellipsis1 0.6s infinite;
}
  
.Rueda_loading div:nth-child(2) {
    left: 8px;
    background: var(--Color-Negro);
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
}
  
.Rueda_loading div:nth-child(3) {
    left: 32px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
    background: var(--Color-Red-Primary);
}
  
.Rueda_loading div:nth-child(4) {
    left: 56px;
    -webkit-animation: lds-ellipsis3 0.6s infinite;
            animation: lds-ellipsis3 0.6s infinite;
}
  
@-webkit-keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
}
  
@keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
}
  
@-webkit-keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
}
  
@keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
}
  
@-webkit-keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
}
  
@keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
}

.fade-in {
    transition: opacity 1s ease;
}
  
.fade-out {
    opacity: 0;
    transition: opacity 1s ease;
}

nav{
    background-color: var(--Color-Blanco) !important; 
    box-shadow: 0px 4px 19px -5px rgba(0, 0, 0, 0.25);
}

.content-navbar{
    justify-content: center;

}

.content-navbar ul{
    justify-content: space-around;
}

.navbar-brand {
    width: 15%;
}

.nav-item{
    border-right: 1px solid var(--Color-Red-Primary);
    padding: 10px 30px;
}

.nav-item-last{
    border-right: none;
}

.nav-link{
    font-family: var(--Font-DMSans-Regular);
    color: var(--Color-Negro);
    border-bottom: 1px solid #F8F9FA; 
    transition: all .25s linear;
    font-size: 14px;
}

.nav-link:hover, .active{
    color: var(--Color-Red-Primary)!important;
    border-bottom: 1px solid var(--Color-Red-Primary) !important;
}

.rueda_digital_toggleUser_nav{
    margin: 0!important;
    padding: 10px!important;
    background-color: var(--Color-Red-Primary);
    border-radius: 15px;
    border: none !important;
}

.rueda_digital_toggleUser_nav .nav-item{
    border: none !important;
    width: 100%;
    padding: 0 !important;
    text-align: center;
}

.rueda_digital_toggleUser_nav .nav-item a{
    margin: 0;
    padding: 0;
    border-bottom: none;
    color: var(--Color-Blanco);
    font-family: var(--Font-DMSans-Regular);
    width: 100%;
}

.rueda_digital_toggleUser_nav .nav-item a:hover{
    border-bottom: none !important;
    color: var(--Color-Negro) !important;
}

.rueda_digital_toggleUser_nav .nav-item a.linkOpcionUsuario:hover{
    background-color: var(--Color-Blanco);
    color: var(--Color-Red-Primary) !important;
}


.dropdown-toggle::after{
    display: none;
}

.contentLinkOpcionUsuario{
    width: 100%;
    margin-top: 15% !important;
    padding-left: 15px;
}


.contentLinkOpcionUsuario li a {
    color: var(--Color-Negro) !important;
}


.small_input{
    font-size: 14px;
    color: var(--Color-Negro);
}

.Rueda_InputInicio{
    width: 100%;
    padding: 20px;
    margin-top: 5px;
    border: 1px solid var(--Color-Grey-Light);
    border-radius: 20px;
    outline: none !important;
    box-shadow: 1px 1px 1px 1px var(--Color-White) !important;
    transition: all .25s linear;
}

.Rueda_InputInicio::-webkit-input-placeholder{
    font-family: var(--Font-DMSans-Regular);
    font-size: 14px;
}

.Rueda_InputInicio:-ms-input-placeholder{
    font-family: var(--Font-DMSans-Regular);
    font-size: 14px;
}

.Rueda_InputInicio::placeholder{
    font-family: var(--Font-DMSans-Regular);
    font-size: 14px;
}

.Rueda_InputInicio:focus-visible{
    -webkit-transform: scale(0.9)!important;
            transform: scale(0.9)!important;
    box-shadow: 1px 1px 1px 1px var(--Color-Grey-Dark) !important;
}

.Rueda_InputContacto{
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid var(--Color-Red-Primary);
    border-radius: 15px;
    outline: none !important;
    box-shadow: 1px 1px 1px 1px var(--Color-White) !important;
    transition: all .25s linear;
}

.Rueda_InputContacto::-webkit-input-placeholder{
    font-family: var(--Font-DMSans-Regular);
    font-size: 14px;
    color: var(--Color-Grey-Dark);
}

.Rueda_InputContacto:-ms-input-placeholder{
    font-family: var(--Font-DMSans-Regular);
    font-size: 14px;
    color: var(--Color-Grey-Dark);
}

.Rueda_InputContacto::placeholder{
    font-family: var(--Font-DMSans-Regular);
    font-size: 14px;
    color: var(--Color-Grey-Dark);
}

.Rueda_InputContacto:focus-visible{
    -webkit-transform: scale(0.9)!important;
            transform: scale(0.9)!important;
    box-shadow: 1px 1px 1px 1px var(--Color-Red-Primary) !important;
}

.modal-dialog {
    max-width: 50% !important;
}

.modal-content{
    border-radius: 2rem !important;
}


.carousel-item{
    border-bottom: none !important;
}

.carousel-item img {
    border-radius: 20px;
}

.carousel-indicators .active{
    background-color:  var(--Color-Red-Primary) !important;
}

#carouselExampleControls{
    width: 100vh;
}

.carrouselTexto{
    width: 100vw !important;
    text-align: center;
}

.carousel-item-texto{
    padding: 50px;
}

.rueda_digital_btn_olvidePass{
    text-decoration: none;
    text-align: right;
}

.btn-group{
    width: 100%;
}

.rueda_digital_toggleUser{
    background-color: var(--Color-Red-Primary);
    border: none;
    border-radius: 15px !important;
    width: 100%;
    font-family: var(--Font-DMSans-Regular);
    font-size: 14px;
   
}

.rueda_digital_toggleUser:focus, .rueda_digital_toggleUser:hover{
    background-color: var(--Color-Red-Primary);
    color: var(--Color-Grey-Light)!important;
}

.rueda_digital_toggleUser{
    text-align: right;
}

.rueda_digital_toggleUser i{
    margin-left: 10%;
}

.linkOpcionUsuario{
    text-decoration: none;
    color: var(--Color-Negro) !important;
    font-family: var(--Font-DMSans-Regular);
    font-size: 14px;
    margin-bottom: 3% !important;
    transition: all .25s linear;
    background-color: var(--Color-Blanco) !important;
    transition: all .25s linear;
}



.linkOpcionUsuario:hover{
    text-decoration: none;
    background-color: none !important;
    color: var(--Color-Grey-Dark) !important;
    font-family: var(--Font-DMSans-Regular);
    font-size: 14px;

}

.accordion-item{
    margin-top: 2%;
    margin-bottom: 2%;
    border: none !important;
}

.accordion-header{
    color: var(--Color-Negro) !important;
    
}

.accordion-button{
    background-color: var(--Color-Blanco) !important;
    box-shadow: 0px 4px 30px -4px rgba(104, 117, 130, 0.2) !important;
    border-radius: 15px;
    font-family: var(--Font-DMSans-Bold);
    position: relative;
    padding: 50px !important;
    color: var(--Color-Red-Primary) !important;
    font-size: 20px;

    
}

.icono-accordion{
    position: absolute !important;
    left: 50px;
    
    
}

.span-accordion {
    position: absolute !important;
    left: 100px;
    color: var(--Color-Negro) !important;
    font-size: 18px;
}

.accordion-button::after{
    display: none;
}

.accordion-body{
    text-align: left !important;
    background-color: var(--Color-Grey-Light);
    padding-top: 50px;
    margin-top: -25px;
    border-radius: 15px;
}

.item-ayuda{
    list-style: disc outside none;
    list-style: initial;
    font-family: var(--Font-DMSans-Regular);
    margin-top: 1%;
    font-size: 16px;
}

.navbar{

    padding: 2% 5%;
}

.navbar-nav {
    width: 75%;
    justify-content: center;
}

.content-drop-usuario{
    padding: 0 !important;
    margin-bottom: 0 !important;
}

.content-drop-usuario li{
    border-right: none !important;
}

.btn-drop-usuario{

    display: flex;
    justify-content: center;
    
}

.btn-drop-usuario a{
    background-color: var(--Color-Red-Primary);
    border-radius: 15px;
    font-family: var(--Font-DMSans-Regular);
    color: var(--Color-Blanco);
}

.btn-drop-usuario a:hover{
    color: var(--Color-Grey-Dark)  !important;
}

.btn-drop-usuario a:focus{
    color: var(--Color-Blanco)  !important;
}

.small_ARS{
    font-size: 10px;
    font-family: var(--Font-DMSans-Regular);
}

.content_input_busqueda{
    width: 85%;
}



.input_busqueda{
    border: 1px solid #BABABA;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    transition: all .5s linear;
    cursor: pointer;
    font-family: var(--Font-DMSans-Regular);
    padding-left: 3.5% !important;
}

.input_busqueda:focus{
    border-color: var(--Color-Red-Primary)!important;
    box-shadow: none !important;
}

.label_busqueda{
    color: #BABABA;
    font-family: var(--Font-DMSans-Regular);
    font-size: 95%;

}

.label_busqueda i, .button_busqueda i {
    margin-right: 5px;
}


.button_busqueda{
    width: 15%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid var(--Color-Red-Primary);
    font-family: var(--Font-DMSans-Regular);
    font-size: 85%;
    color: var(--Color-Red-Primary) !important;
    transition: all .5s linear;
}

.button_busqueda:focus{
    box-shadow: 0 0 0 0.25rem rgb(228 43 49 / 50%);
    border-color: var(--Color-Red-Primary) !important;
    color: var(--Color-Red-Primary);
    background-color: #FFF !important;
}

.button_busqueda:active, .button_busqueda:hover{
    background-color: var(--Color-Red-Primary)!important;
    border-color: var(--Color-Red-Primary) !important;
    color: #FFF !important;
}


@media (max-width: 480px) {

    .mobile-none{
        display: none;
    }

    .navbar{
        margin-bottom: 5%;
        padding: 5%;
    }
    
    .navbar-brand {
        width: 40%;
    }

    .nav-item{
        border-right: none;
    }

    .accordion-button{
        padding: 25px !important;
        margin-bottom: 5%;
    }

    .span-accordion{
        font-size: 14px;
        left: 50px;
    }

    .icono-accordion{
        left: 25px;
    }

    .Rueda_InputContacto::-webkit-input-placeholder{
        font-size: 12px;
    }

    .Rueda_InputContacto:-ms-input-placeholder{
        font-size: 12px;
    }

    .Rueda_InputContacto::placeholder{
        font-size: 12px;
    }

    .btn-drop-usuario{
        justify-content: flex-start;
    }

    .modal-dialog {
        max-width: 90% !important;
        margin-left: 5%;
        margin-top: 20%;
    }

    .content_input_busqueda{
        width: 70%;
    }

    .button_busqueda{
        width: 30%;
    }
    
}
