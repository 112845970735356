@font-face {
  font-family: 'Roboto-Black';
  src: url('./assets/Fonts/Roboto/Roboto-Black.ttf');
}

@font-face {
  font-family: 'Roboto-BlackItalic';
  src: url('./assets/Fonts/Roboto/Roboto-BlackItalic.ttf');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('./assets/Fonts/Roboto/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'Roboto-BoldItalic';
  src: url('./assets/Fonts/Roboto/Roboto-BoldItalic.ttf');
}

@font-face {
  font-family: 'Roboto-Italic';
  src: url('./assets/Fonts/Roboto/Roboto-Italic.ttf');
}

@font-face {
  font-family: 'Roboto-Light';
  src: url('./assets/Fonts/Roboto/Roboto-Light.ttf');
}

@font-face {
  font-family: 'Roboto-LightItalic';
  src: url('./assets/Fonts/Roboto/Roboto-LightItalic.ttf');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('./assets/Fonts/Roboto/Roboto-Medium.ttf');
}

@font-face{
  font-family: 'Roboto-MediumItalic';
  src: url('./assets/Fonts/Roboto/Roboto-MediumItalic.ttf');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('./assets/Fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto-Thin';
  src: url('./assets/Fonts/Roboto/Roboto-Thin.ttf');
}

@font-face {
  font-family: 'Roboto-ThinItalic';
  src: url('./assets/Fonts/Roboto/Roboto-ThinItalic.ttf');
}


@font-face {
  font-family: 'DMSans-Bold';
  src: url('./assets/Fonts/DM_Sans/DMSans-Bold.ttf');
}

@font-face {
  font-family: 'DMSans-BoldItalic';
  src: url('./assets/Fonts/DM_Sans/DMSans-BoldItalic.ttf');
}

@font-face {
  font-family:'DMSans-Italic' ;
  src: url('./assets/Fonts/DM_Sans/DMSans-Italic.ttf');
}

@font-face {
  font-family: 'DMSans-Medium';
  src: url('./assets/Fonts/DM_Sans/DMSans-Medium.ttf');
}

@font-face {
  font-family: 'DMSans-MediumItalic';
  src: url('./assets/Fonts/DM_Sans/DMSans-MediumItalic.ttf');
}

@font-face {
  font-family:'DMSans-Regular' ;
  src: url('./assets/Fonts/DM_Sans/DMSans-Regular.ttf');
}


:root{
  --Color-Blanco: #fff;
  --Color-Negro: #000;
  --Color-Red-Primary: rgb(228, 43, 49);
  --Color-Grey: #EEEEEE;
  --Color-Grey-Dark:  #7F7F7F;
  --Color-Grey-Light: #F6F6F6;
  --Color-Green-Aproved: #41B96A;
  --Color-Yellow-Paused: #FFC120;

  --Font-Roboto-Regular: 'Roboto-Regular';
  --Font-Roboto-Thin: 'Roboto-Thin';
  --Font-Roboto-Black: 'Roboto-Bold';
  
  --Font-DMSans-Bold: 'DMSans-Bold';
  --Font-DMSans-BoldItalic: 'DMSans-BoldItalic';
  --Font-DMSans-Italic: 'DMSans-Italic';
  --Font-DMSans-Medium:'DMSans-Medium';
  --Font-DMSans-MediumItalic: 'DMSans-MediumItalic';
  --Font-DMSans-Regular: 'DMSans-Regular';

}